import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext, PaginationContext } from '~/contexts';
import { UsersContext } from '~/contexts/forms/users';

// Components
import Admin from '~/components/Admin';
import { LoadingSingle } from '~/components/Default/Loading';
import UsersBox from '~/components/Boxs/AdminUsers';
import FormUsers from './Form';

const AdminUsers: React.FC<any> = () => {
    const navigate            = useNavigate();
    const [ready, setReady]   = useState<Boolean>();
    const [users, setUsers]   = useState<any>([]);
    const [search, setSearch] = useState('');

    // Context
    const {user}     = useContext(GeneralContext);
    const Modal      = useContext(ModalContext);
    const Users      = useContext(UsersContext);
    const Pagination = useContext(PaginationContext);

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let usersFilter = users.filter((el: any) =>
            el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0
            ? true : false
        )

        setSearch(data);
        Pagination.setCurrentPage(1);
        Pagination.setData(usersFilter);
    }

    function openModal (modal: string, data?: any) {
        let newUpdate     = false;
        let dataUser: any = null;

        Modal.setModalOpen(false);
        Modal.setModalClass('musers');

        switch (modal) {
            case 'addUser':
                Modal.setModalTitle('Adicionando Usuário')

                dataUser = {
                    id       : 0,
                    type     : 'user',
                    image    : '',
                    name     : '',
                    mail     : '',
                    active   : 1,
                    password : ''
                }

                newUpdate = true;
                break;

            case 'editUser':
                Modal.setModalTitle('Editando Usuário')

                if (data) {
                    dataUser = {
                        id      : data.id,
                        type    : data.type,
                        image   : data.image_url,
                        name    : data.name,
                        mail    : data.mail,
                        active  : data.active,
                        password: ''
                    }
                }

                newUpdate = true;
                break;

            case 'deleteUser':
                Modal.setModalClass('delete')
                Modal.setModalTitle('Excluindo Usuário')
                Modal.setModalBody(<>
                    <div>Deseja realmente excluir o usuário <i>"{ data?.name }"?</i></div>
                    <div className="alert">* Tenha cuidado, esse processo é irreversível!</div>

                    <footer>
                        <button id="btnDelete" type="button" onClick={() => Users.remove(data.id, Modal, setReady)}>Excluir</button>
                        <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
                    </footer>
                </>)
                Modal.setModalOpen(true)
                break;

            default:
                Modal.setModalClass(null);
                Modal.setModalTitle('');
                Modal.setModalBody(<></>);
                break;
        }

        if (newUpdate) {
            Modal.setModalBody(<FormUsers user={dataUser} setReady={setReady} />)
            Modal.setModalOpen(true)
        }
    }

    useEffect(() => {
        // Set Clear Data and Set Items Per Page
        Pagination.init();
        Pagination.setItemsPerPage(9);

        // Check Permission
        if (user.role==='client') navigate('/404', {replace: true})
        else api.get('users', {
            headers: { Authorization: user.token }
        }).then(resp => {
            Pagination.setData(resp.data);
            setUsers(resp.data);
            setReady(true);
        });
    }, [user, Modal.modalOpen]);

    return <Admin pageTitle="Gerenciamento de Usuários - Clientes Serena" title="Gerenciamento" subtitle="Usuários Serena" classMain='adm_users'>
        {
            ready ? <>
                <div className="usersContent">
                    <div className="header">
                        <div className="buttons">
                            <button
                                type="button"
                                className="addUser"
                                onClick={() => openModal('addUser')}
                                data-for="tooltip_users_page"
                                data-tip="Adicionar Usuário"
                            >
                                <FiPlus size="16" />
                            </button>
                        </div>

                        <div className='searchBox'>
                                <input
                                    name="search" id="search"
                                    type="text" autoComplete='false'
                                    placeholder='Nome do usuário...'
                                    value={search} onChange={onChangeSearch}
                                    disabled={ready ? false : true}
                                />

                                <div className={`btn${search !=='' ? ' lnk' : ''}`} onClick={() => {
                                    if (search !== '') {
                                        Pagination.setCurrentPage(1)
                                        Pagination.setData(users)
                                        setSearch('')
                                    }
                                }}>
                                    { search !=='' ? <FaTimes /> : <FaSearch /> }
                                </div>
                            </div>
                    </div>

                    <div className={`users ${!ready ? 'loading' : ''}`}>
                        {
                            Pagination.currentData.map((userData: any) => {
                                return (
                                    <UsersBox
                                        key={'user_' + userData.id}
                                        {...userData}
                                        token={user.token}
                                        edit={() => openModal('editUser', userData)}
                                        delete={() => openModal('deleteUser', userData)}
                                    />
                                )
                            })
                        }
                    </div>
                </div>

                { Pagination.renderPagination() }

                <ReactTooltip
                    id="tooltip_users_page"
                    place="top"
                    effect="solid"
                    type="info"
                />
            </> : <LoadingSingle />
        }
    </Admin>
}

export default AdminUsers;
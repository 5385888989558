import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaTimes } from 'react-icons/fa';
import api from '~/services/api';
import sort_by from '~/utils/sort';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext, PaginationContext } from '~/contexts';

// Components
import Admin from '~/components/Admin';
import { LoadingSingle } from '~/components/Default/Loading';
import UsersPermission from '~/components/Boxs/AdminPermission';
import FormUserPermissions from './Form';

const AdminPermissions: React.FC<any> = () => {
    const navigate            = useNavigate();
    const [ready, setReady]   = useState<Boolean>();
    const [users, setUsers]   = useState<any>([]);
    const [groups, setGroups] = useState<[]>();
    const [units, setUnits]   = useState<[]>();
    const [search, setSearch] = useState('');

    // Context
    const {user}     = useContext(GeneralContext);
    const Modal      = useContext(ModalContext);
    const Pagination = useContext(PaginationContext);

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let usersFilter = users.filter((el: any) =>
            el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0
            ? true : false
        )

        setSearch(data);
        Pagination.setCurrentPage(1);
        Pagination.setData(usersFilter);
    }

    function openModal(modal: any, data?: any) {
        Modal.setModalOpen(false);
        Modal.setModalClass('mpermissions');

        switch (modal) {
            case 'editPermission':
                if (data) {
                    Modal.setModalTitle('Editando Permissões');

                    let dataPermission = null;

                    if (data.length && data.length>0) {
                        dataPermission = [];

                        data.map((el: any) => {
                            dataPermission.push({
                                user_id  : el.user_id ? parseInt(el.user_id) : 0,
                                client_id: el.client_id ? parseInt(el.client_id) : 0,
                                unit_id  : el.unit_id ? parseInt(el.unit_id) : 0,
                            });
                        });
                    } else {
                        dataPermission = {
                            user_id  : data.user_id ? parseInt(data.user_id) : 0,
                            client_id: data.client_id ? parseInt(data.client_id) : 0,
                            unit_id  : data.unit_id ? parseInt(data.unit_id) : 0,
                        }
                    }

                    Modal.setModalBody(<FormUserPermissions permission={dataPermission} groups={groups || []} units={units || []} setReady={setReady} />);
                    Modal.setModalOpen(true)
                }
                break;

            default:
                Modal.setModalClass(null);
                Modal.setModalTitle('');
                Modal.setModalBody(<></>);
                break;
        }
    }

    useEffect(() => {
        // Set Clear Data and Set Items Per Page
        Pagination.init();
        Pagination.setItemsPerPage(9);

        // Check Permission
        if (user.role==='client') navigate('/404', {replace: true})
        else !ready && api.get('users/permissions', {
            headers: { Authorization: user.token }
        }).then(resp => {
            Pagination.setData(resp.data.users.sort(sort_by({ name: 'type' }, { name: 'name' })));
            setUsers(resp.data.users.sort(sort_by({ name: 'type' }, { name: 'name' })));
            setGroups(resp.data.groups);
            setUnits(resp.data.units);
            setReady(true);
        });
    }, [user, ready]);

    return <Admin pageTitle="Gerenciamento de Permissões - Clientes Serena" title="Gerenciamento" subtitle="Permissões dos Clientes" classMain='adm_permissions'>
        {
            ready ? <>
                <div className='permissionContent'>
                    <div className="header">
                        <div className="buttons"></div>

                        <div className='searchBox'>
                                <input
                                    name="search" id="search"
                                    type="text" autoComplete='false'
                                    placeholder='Nome do usuário...'
                                    value={search} onChange={onChangeSearch}
                                    disabled={ready ? false : true}
                                />

                                <div className={`btn${search !=='' ? ' lnk' : ''}`} onClick={() => {
                                if (search !== '') {
                                        Pagination.setCurrentPage(1)
                                        Pagination.setData(users)
                                        setSearch('')
                                    }
                                }}>
                                    { search !=='' ? <FaTimes /> : <FaSearch /> }
                                </div>
                            </div>
                    </div>

                    <div className="users">
                        {
                            Pagination.currentData.map((user: any, index: number) => <UsersPermission
                                key={`key_box_${index}`}
                                {...user}
                                edit={() => openModal('editPermission', user.permission)}
                            />)
                        }
                    </div>
                </div>

                { Pagination.renderPagination() }
            </> : <LoadingSingle />
        }
    </Admin>
}

export default AdminPermissions;
import { createContext, ReactNode, useEffect, useState } from "react";
import api from "~/services/api";
import cryptos from "~/utils/cryptos";
import storage from "~/utils/storage";
import apiIP from '~/services/getIp';
import './styles.css';

// Logo Serena
import logo from '~/assets/images/logo_serena_minimal.svg';

interface UserInterface {
    id          : number;
    role        : string;
    image       : string | null;
    image_url   : string | null;
    name        : string;
    mail        : string;
    active      : number;
    token       : string;
    refreshToken: string;
    api         : string;
}

interface GeneralContextData {
    user          : UserInterface;
    unit          : any;
    units         : any[] | null;
    breadcrumbs   : any;
    setBreadcrumbs: Function;
    logged        : boolean;
    changeUnit    : Function;
    getUnit       : Function;
    login         : Function;
    logout        : Function;
    updateUser    : Function;
}

interface GeneralProviderProps {
    children: ReactNode;
}

// User Init
const userInit: UserInterface = {
    id          : 0,
    role        : 'admin',
    image       : null,
    image_url   : null,
    name        : 'Guest',
    mail        : 'guest@guest.com',
    active      : 1,
    token       : '',
    refreshToken: '',
    api         : ''
}

export const GeneralContext = createContext({} as GeneralContextData)

export function GeneralProvider ({ children }: GeneralProviderProps) {
    const [ready, setReady]     = useState<boolean>(false);
    const [logged, setLogged]   = useState<boolean>(false);
    const [breadcrumbs, setBreadcrumbs] = useState<any>(null);
    const [user, setUser]       = useState<UserInterface>(userInit);
    const [units, setUnits]     = useState<any>(null);
    const [unit, setUnit]       = useState<any>(null);

    function login (userData: any) {
        let data = cryptos.encryptWeb(userData)

        // Set Data to System
        storage.set('UToken', data)
        setUser(userData)
        getUnits(userData.token)
        setLogged(true)
    }

    function logout () {
        storage.remove('UToken')
        storage.remove('Unit')
        setUser(userInit)
        setLogged(false)
    }

    function updateUser (userData: any) {
        delete userData.change;
        let data = cryptos.encryptWeb({...user, ...userData})
        storage.set('UToken', data)
        setUser({...user, ...userData})
    }

    async function checkToken (user: any) {
        let userData: any = null;
        let ip = await apiIP.get();
        if (ip === user.ip){

            await api.post('validateToken', {data: cryptos.encryptServer({token: user.token, refresh: user.refreshToken, ip})})
            .then(async (resp: any) => {
                if (resp.data.valid && resp.data.user==="") {
                    userData = user;
                    setUser(user)
                } else {
                    userData = cryptos.decryptServer(resp.data.user);
                    let data = cryptos.encryptWeb(userData);

                    storage.set('UToken', data)
                    setUser(userData)
                }

                setLogged(true)
                return true
            }).catch((error: any) => {
                storage.remove('UToken')
                setUser(userInit)
                setLogged(false)
                setReady(true)
                return false
            })
        } else {
            alert('Faça o login novamente')
            logout()
        }

        await getUnits(user.token)

        setReady(true)
    }

    async function getUnits(token:string){
        await api.get('clients/units', {
            headers: { Authorization: token }
        })
        .then(async (resp: any) => {
            setUnits(resp.data)
            if (!unit && !storage.get('Unit')) {
                setUnit(resp.data[0]);
                storage.set('Unit', resp.data[0].id);
            } else if (storage.get('Unit')) {
                let unitData = storage.get('Unit');
                let unitSel  = resp.data.filter((el: any) => el.id===Number(unitData));
                setUnit(unitSel[0]);
            }
            return true
        }).catch((error: any) => {
            setUnits(null)
            return false
        })
    }

    async function getUnit(){
        await api.get('clients/units', {
            headers: { Authorization: user.token }
        })
        .then(async (resp: any) => {
            if (!unit && !storage.get('Unit')) {
                setUnit(resp.data[0]);
                storage.set('Unit', resp.data[0].id);
            } else if (storage.get('Unit')) {
                let unitData = storage.get('Unit');
                let unitSel  = resp.data.filter((el: any) => el.id===Number(unitData));
                setUnit(unitSel[0]);
            }
            return resp.data[0]
        }).catch((error: any) => {
            return error
        })
    }

    function changeUnit (unit: number) {
        let unitSel  = units.filter((el: any) => el.id===unit);
        storage.set('Unit', unitSel[0].id);
        setUnit(unitSel[0]);
        setReady(false);
    }

    useEffect(() => {
        if (!ready) {
            let data = storage.get('UToken');

            if (data) {
                let userData = cryptos.decryptWeb(data);
                checkToken(userData);
            } else {
                setUser(userInit)
                setLogged(false)
                setReady(true)
            }
        }
    }, [ready]);

    return (
        <GeneralContext.Provider value={{user,breadcrumbs, setBreadcrumbs, unit, units, getUnit, changeUnit, login, logout, logged, updateUser}}>
            {
                ready ? children : <div className="loading">
                    <img src={logo} alt="Loading..." />
                    <span>Carregando...</span>
                </div>
            }
        </GeneralContext.Provider>
    )
}
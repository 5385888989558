import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

// Contexts
import { GeneralProvider, FormsProvider, ThemeProvider, ModalProvider, MixPanelProvider, PaginationProvider } from './contexts';

// Routes
import Routes from '~/routes';

const App: React.FC = () => {
	return (
		<HelmetProvider>
			<MixPanelProvider>
				<ThemeProvider initialTheme="light">
					<GeneralProvider>
						<PaginationProvider>
							<FormsProvider>
								<ModalProvider>
									<Routes />
								</ModalProvider>
							</FormsProvider>
						</PaginationProvider>
					</GeneralProvider>
				</ThemeProvider>
			</MixPanelProvider>
		</HelmetProvider>
	)
}

export default App;

import { createContext, ReactNode, useEffect, useState } from "react";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md';
import './styles.css';

interface PaginationContextData {
    init: Function;
    renderPagination: Function;
    setData         : Function;
    setItemsPerPage : Function;
    setCurrentPage  : Function;
    currentData     : any;
}

interface PaginationProviderProps {
    children: ReactNode;
}

export const PaginationContext = createContext({} as PaginationContextData)

export function PaginationProvider({ children }: PaginationProviderProps) {
    const [ready, setReady]               = useState<Boolean>();
    const [listData, setListData]         = useState([]);
    const [currentData, setCurrData]      = useState([]);
    const [currentPage, setCurrPage]      = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);

    const init = () => {
        setReady(false)
        setCurrData([])
    }

    const setData = (data: any) => {
        setReady(false);
        setListData(data);
    }

    const setCurrentPage = (page: number) => {
        setReady(false);
        setCurrPage(page);
    }

    const handleNextPage = () => {
        if (currentPage < Math.ceil(listData.length / itemsPerPage))
            setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1)
            setCurrentPage(currentPage - 1);
    };

    const renderPagination = () => {
        return <div className="pagination">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}><MdOutlineNavigateBefore /></button>
            <span>Página {currentPage} de {Math.ceil(listData.length / itemsPerPage)}</span>
            <button onClick={handleNextPage} disabled={currentPage === Math.ceil(listData.length / itemsPerPage)}><MdOutlineNavigateNext /></button>
        </div>
    }

    useEffect(() => {
        if (!ready) {
            const indexOfLastItem = currentPage * itemsPerPage;
            const indexOfFirstItem = indexOfLastItem - itemsPerPage;
            setCurrData(listData.slice(indexOfFirstItem, indexOfLastItem));
            setReady(true);
        }
    }, [listData, currentPage]);

    return (
        <PaginationContext.Provider value={{
            init,
            renderPagination,
            setData,
            setItemsPerPage,
            setCurrentPage,
            currentData
        }}>
            { children }
        </PaginationContext.Provider>
    )
}
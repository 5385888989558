import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import InputMask from 'react-input-mask';
import CurrencyInput from '~/components/Modules/CurrencyMask';
import './styles.css';

// Contexts
import { ModalContext } from '~/contexts';
import { UnitsContext } from '~/contexts/forms/units';
import funcs from '~/utils/funcs';

const Form: React.FC<any> = ({unit, groups, providers, setReady}) => {
    const [unitData, setUnitData] = useState<any>(null);
    const [content, setContent]   = useState<string>('unit');

    // Contexts
    const Units = useContext(UnitsContext);
    const Modal = useContext(ModalContext);

    function changeTab(tab: string) {
        if (tab!==content) setContent(tab)
    }

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {classList, name, value} = event.target;
        const upper = (classList.contains('upC') ? true : false);
        let newData = JSON.parse(JSON.stringify(unitData))
        let data    = funcs.onChange(newData, name, value, upper);
        setUnitData(data);
    }

    function onChangeFloat(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, originalValue: any) {
        let {name}  = event.target;
        let newData = JSON.parse(JSON.stringify(unitData))
        let data    = funcs.onChangeFloat(newData, name, originalValue);
        setUnitData(data);
    }

    useEffect(() => {
        if (!unitData) setUnitData(unit)
    }, [unit, unitData])

    return (
        unitData && <>
            <div className="tabs">
                <div className={ `tab-unit${content==='unit' ? ' active' : ''}` } onClick={() => changeTab('unit') }>Unidade</div>
                <div className={ `tab-provider${content==='provider' ? ' active' : ''}` } onClick={() => changeTab('provider') }>Distribuidora</div>
            </div>

            <div className="contents">
                <div className={ `unit${content==='unit' ? ' active' : ''}` }>
                    <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editClient">
                        <div className="form-group">
                            <div className="input-group">
                                <label htmlFor="client">Cliente</label>

                                <select
                                    name="client_id" id="client" onChange={onChange}
                                    defaultValue={unitData.client_id ? unitData.client_id : ''}
                                >
                                    <option value="">Nenhum</option>

                                    {
                                        groups.map((el: any) => (
                                            <option key={`units_${el.id}`} value={el.id}>{ el.name }</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="input-group w250">
                                <label htmlFor="proinfa_code">Contrato Proinfa</label>
                                <InputMask id="proinfa_code" className={unitData.errors && unitData.errors.proinfa_code ? 'error' : ''} name="proinfa_code" type="text" placeholder="Contrato Proinfa" mask="9999999999" maskChar={null} value={unitData.proinfa_code ? String(unitData.proinfa_code).replace('.', ',') : ''} onChange={onChange} />
                            </div>

                            <div className="input-group w200">
                                <label htmlFor="init_contract">Início <i>(01/2020)</i></label>
                                <InputMask id="init_contract" className={unitData.errors && unitData.errors.init_contract ? 'error' : ''} name="init_contract" type="text" placeholder="Início Contrato" mask="99/9999" maskChar={null} value={unitData.init_contract} onChange={onChange} />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="input-group">
                                <label htmlFor="name">Nome</label>
                                <input id="name" className={unitData.errors && unitData.errors.name ? 'error' : ''} name="name" type="text" placeholder="Nome" value={unitData.name} onChange={onChange} />
                            </div>

                            <div className="input-group w220">
                                <label htmlFor="cost_reference">Custo Referência</label>

                                <CurrencyInput
                                    id="cost_reference" name="cost_reference" placeholder="0,00"
                                    onChangeValue={onChangeFloat}
                                    value={unitData.cost_reference ? unitData.cost_reference : ''}
                                    hideSymbol
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="input-group w110">
                                <label htmlFor="client_type">Cliente ({unitData.client_type})</label>
                                <select
                                    name="client_type" id="client_type"
                                    className={unitData.errors && unitData.errors.client_type ? 'error' : ''}
                                    onChange={onChange} defaultValue={unitData.client_type ? unitData.client_type : ''}
                                >
                                    <option value="energizou">Energizou</option>
                                    <option value="serena">Serena</option>
                                </select>
                            </div>

                            <div className="input-group w180">
                                <label htmlFor="cnpj">CNPJ</label>
                                <InputMask id="cnpj" className={unitData.errors && unitData.errors.cnpj ? 'error' : ''} name="cnpj" type="text" placeholder="CNPJ" mask="99.999.999/9999-99" maskChar={null} value={unitData.cnpj} onChange={onChange} />
                            </div>

                            <div className="input-group w180">
                                <label htmlFor="cnpj_matriz">CNPJ Matriz (Opcional)</label>
                                <InputMask id="cnpj_matriz" className={unitData.errors && unitData.errors.cnpj_matriz ? 'error' : ''} name="cnpj_matriz" type="text" placeholder="CNPJ" mask="99.999.999/9999-99" maskChar={null} value={unitData.cnpj_matriz ? unitData.cnpj_matriz : ''} onChange={onChange} />
                            </div>

                            <div className="input-group w180">
                                <label htmlFor="meter">Medidor</label>
                                <input id="meter" className={unitData.errors && unitData.errors.meter ? 'error' : ''} name="meter" type="text" placeholder="Medidor" value={unitData.meter} onChange={onChange} />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="input-group w220">
                                <label htmlFor="agent">Perfil ML</label>
                                <InputMask id="agent" className={unitData.errors && unitData.errors.agent ? 'error' : ''} name="agent" type="text" placeholder="Perfil ML" mask="999999" maskChar={null} value={unitData.agent ? String(unitData.agent).replace('.', ',') : ''} onChange={onChange} />
                            </div>

                            <div className="input-group w220">
                                <label htmlFor="profile">Agente</label>
                                <InputMask id="profile" className={unitData.errors && unitData.errors.profile ? 'error' : ''} name="profile" type="text" placeholder="Agente" mask="999999" maskChar={null} value={unitData.profile ? String(unitData.profile).replace('.', ',') : ''} onChange={onChange} />
                            </div>

                            <div className="input-group w140">
                                <label htmlFor="active">Status</label>
                                <select
                                    name="active" id="active"
                                    className={unitData.errors && unitData.errors.active ? 'error' : ''}
                                    onChange={onChange} defaultValue={unitData.active ? "1" : "0"}
                                >
                                    <option value="1">Ativo</option>
                                    <option value="0">Inativo</option>
                                </select>
                            </div>
                        </div>

                        <hr />

                        <div className="input-group">
                            <label htmlFor="address">Endereço</label>
                            <input id="address" className={unitData.errors && unitData.errors.address ? 'error' : ''} name="address" type="text" placeholder="Endereço" value={unitData.address} onChange={onChange} />
                        </div>

                        <div className="form-group">
                            <div className="input-group">
                                <label htmlFor="city">Cidade</label>
                                <input id="city" className={unitData.errors && unitData.errors.city ? 'error' : ''} name="city" type="text" placeholder="Cidade" value={unitData.city} onChange={onChange} />
                            </div>

                            <div className="input-group w140">
                                <label htmlFor="state">Estado</label>
                                <InputMask id="state" className={unitData.errors && unitData.errors.state ? 'upC error' : 'upC'} name="state" type="text" placeholder="Estado" mask="aa" maskChar={null} value={unitData.state} onChange={onChange} />
                            </div>
                        </div>
                    </form>
                </div>

                <div className={ `provider${content==='provider' ? ' active' : ''}` }>
                    <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editClient">
                        <div className="form-group">
                            <div className="input-group">
                                <label htmlFor="provider">Distribuidora</label>
                                <select
                                    name="provider.provider_id" id="provider"
                                    className={unitData.errors && unitData.errors['provider.provider_id'] ? 'error' : ''}
                                    onChange={onChange} defaultValue={unitData.provider.provider_id ? unitData.provider.provider_id : '0'}
                                >
                                    <option value="0">Selecione a distribuidora</option>

                                    <optgroup label="Distribuidoras">
                                        {
                                            providers.map((el: any) => (
                                                <option key={`providers_${el.id}`} value={el.id}>{ el.name }</option>
                                            ))
                                        }
                                    </optgroup>
                                </select>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="input-group">
                                <label htmlFor="type">Tipo</label>
                                <select
                                    name="provider.type" id="type"
                                    className={unitData.errors && unitData.errors['provider.type'] ? 'error' : ''}
                                    onChange={onChange} defaultValue={unitData.provider.type}
                                >
                                    <option value="conv">Convencional</option>
                                    <option value="inc">Incentivada</option>
                                </select>
                            </div>

                            <div className="input-group">
                                <label htmlFor="modality">Modalidade</label>
                                <select
                                    name="provider.modality" id="modality"
                                    className={unitData.errors && unitData.errors['provider.modality'] ? 'error' : ''}
                                    onChange={onChange}
                                    defaultValue={unitData.provider.modality}
                                >
                                    <option value="Azul">Azul</option>
                                    <option value="Verde">Verde</option>
                                </select>
                            </div>

                            <div className="input-group">
                                <label htmlFor="power">Energia</label>
                                <select
                                    name="provider.power" id="power"
                                    className={unitData.errors && unitData.errors['provider.power'] ? 'error' : ''}
                                    onChange={onChange}
                                    defaultValue={unitData.provider.power}
                                >
                                    <option value="A3">A3</option>
                                    <option value="A4">A4</option>
                                    <option value="AS">AS</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <footer>
                <button id="btnSave" type="button" onClick={() => Units.save(unitData, Modal, setReady)}>Salvar</button>
                <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
            </footer>
        </>
    );
}

export default Form;
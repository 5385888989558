import React, { useState, useContext, useEffect } from 'react';
import { FaPlus, FaSearch, FaTimes } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import api from '~/services/api';
import sort_by from '~/utils/sort';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext, PaginationContext } from '~/contexts';

// Components
import Admin from '~/components/Admin';
import { LoadingSingle } from '~/components/Default/Loading';
import MailBox from '~/components/Boxs/Mailers/Mails';
import FormMail from './Form/New';
import FormMailEdit from './Form/Edit';

const AdminMailer: React.FC<any> = () => {
    const [ready, setReady]     = useState<Boolean>(false);
    const [mailers, setMailers] = useState<any>([]);
    const [search, setSearch]   = useState('');

    // Context
    const {user}     = useContext(GeneralContext);
    const Modal      = useContext(ModalContext);
    const Pagination = useContext(PaginationContext);

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let mailersFilter = mailers.filter((el: any) =>
            el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
            (el.mail && el.mail.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0) ||
            (el.unit.name && el.unit.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0)
            ? true : false
        )

        setSearch(data)
        Pagination.setCurrentPage(1);
        Pagination.setData(mailersFilter);
    }

    function openModal(modal: any, data?: any) {
        Modal.setModalOpen(false);

        switch (modal) {
            case 'addMail':
                Modal.setModalClass('maddmailers');
                Modal.setModalTitle('Adicionar novo email');
                Modal.setModalBody(<FormMail setReadyPage={setReady} />)
                break;

            case 'editMail':
                Modal.setModalClass('meditmailers');
                Modal.setModalTitle('Editar email');
                Modal.setModalBody(<FormMailEdit data={data} setReadyPage={setReady} />)
                break;

            default:
                Modal.setModalClass(null);
                Modal.setModalTitle('');
                Modal.setModalBody(<></>);
                break;
        }

        Modal.setModalOpen(true)
    }

    async function deleteMail (id: number) {
        let delMail = await api.delete(`mailers/delete/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setMailers((prevMailers: any) => prevMailers.filter((mail: any) => mail.id !== id));
            // setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });

        return delMail;
    }

    useEffect(() => {
        // Set Clear Data and Set Items Per Page
        Pagination.init();
        Pagination.setItemsPerPage(11);

        !ready && api.get('mailers/list', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            let datas = resp.data.sort(sort_by('unit_name', {name: 'name'}));
            Pagination.setData(datas)
            setMailers(datas)
            setReady(true)
        })
    }, [ready])

    return <Admin pageTitle="Gerenciamento de Emails - Clientes Serena" title="Gerenciamento" subtitle="Controle de Envio de Emails" classMain='adm_mailer'>
        {
            ready ? <>
                <div className="buttons">
                    <button
                        type="button"
                        className="addMail"
                        onClick={() => openModal('addMail')}
                        data-for="tooltip_mailer_page"
                        data-tip="Gerenciar Envio"
                    >
                        <FaPlus size="16" />
                    </button>
                    <div className='searchBox'>
                        <input
                            name="search" id="search"
                            type="text" autoComplete='false'
                            placeholder='Nome da unidade, Grupo ou Estado...'
                            value={search} onChange={onChangeSearch}
                            disabled={ready ? false : true}
                        />

                        <div className={`btn${search !=='' ? ' lnk' : ''}`} onClick={() => {
                            if (search !=='') {
                                Pagination.setCurrentPage(1)
                                Pagination.setData(mailers)
                                setSearch('')
                            }
                        }}>
                            { search !=='' ? <FaTimes /> : <FaSearch /> }
                        </div>
                    </div>
                </div>

                <div className="mails">
                    {
                        Pagination.currentData.map((el: any) => (
                            <MailBox
                                key={`log_${el.id}`}
                                edit={() => openModal('editMail', el)}
                                delete={() => deleteMail(el.id)}
                                {...el}
                            />
                        ))
                    }
                </div>

                { Pagination.renderPagination() }

                <ReactTooltip
                    id="tooltip_mailer_page"
                    place="top"
                    effect="solid"
                    type="info"
                />
            </> : <LoadingSingle />
            // </> : <h1>Carregando</h1>
        }
    </Admin>
}

export default AdminMailer;